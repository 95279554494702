body {
  margin: 0;
  font-family: 'Oxygen', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.MuiButton-containedPrimary{
  background:#1e90ff !important; 
}

.bullet {
  background: #cc0000;
  padding: 2px;
  width: 8px;
  height: 8px;
  border-radius: 100px;
  margin: 0 auto;
  margin-bottom: 8px;
  margin-top: -31px;
}